import React from 'react'
import { matchIsNodeProduction } from '~/utils/env'

export type TailwindIndicatorProps = never

const TailwindIndicator = () => {
  if (matchIsNodeProduction()) {
    return null
  }

  return (
    <div
      className="fixed bottom-1 right-1 z-[99999] flex items-center justify-center rounded-full bg-gray-800 p-1 font-mono text-xs text-white"
      translate="no"
    >
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block">2xl</div>
    </div>
  )
}

export default TailwindIndicator
