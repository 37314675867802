import React from 'react'
import Script from '~/components/Script'
import { WEBMARKETER_PROJECT_ID } from '~/constants/env'

const Webmarketer = () => {
  return (
    <Script
      innerHtml={`(function (w, d, s, u, n, e, a) {
    w["NDRSTND_ALIAS"] = n; w[n] = w[n] || function (cb) { (w[n].q = w[n].q || []).push(cb); };
    e = d.createElement(s); e.src = u; e.async = 1;
    a = d.getElementsByTagName(s)[0]; a.parentNode.insertBefore(e, a);
  })(window, document, "script", "https://ndrstnd.zoi.com/scripts/ndrstnd-v1.js", "ndrstnd");

  ndrstnd(function (client) {
    window.webmarketerTracker = client.createTracker("${WEBMARKETER_PROJECT_ID}", { ndrstndDomain: "ndrstnd.zoi.com" });
    window.webmarketerTracker.id = window.webmarketerTracker.get("fc");
    window.webmarketerTracker.send("pageview");
  });`}
    />
  )
}

export default Webmarketer
